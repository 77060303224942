import logo from "../src/assets/AOP.jpg";
import "./App.css";
import useDiferencia from "./hooks/useDiferencia";

function App() {
  const { diferencia } = useDiferencia();
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://depor.com/resizer/bgp-ZUHSEyMJw6tbinNGb5FR4io=/580x330/smart/filters:format(jpeg):quality(75)/cloudfront-us-east-1.images.arcpublishing.com/elcomercio/DFOGUL36P5ES5HD7QPZWKVGDEM.jpg"
          className="App-logo"
          alt="logo"
        />
        <p className="proc-text">
          Actualmente me encuentro perdiendo el tiempo... regresa después para
          ver si ya subí algo
        </p>
        <a
          className="App-link"
          href="https://youtu.be/uchtkBC0TsQ?t=3"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mira
        </a>

        <p className="proc-text">Días sin hacer nada: {diferencia}</p>
      </header>
    </div>
  );
}

export default App;
