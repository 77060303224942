import { useState, useEffect } from "react";
import contador from "../contador";
export default () => {
  const [diferencia, setDiferencia] = useState();

  useEffect(() => {
    setDiferencia(contador());
  }, []);

  return { diferencia };
};
